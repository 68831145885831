import { CognitoUser } from '@aws-amplify/auth';
//
import {
  COGNITO_GROUPS_CLAIMS,
  COGNITO_USERNAME_CLAIMS, CUSTOM_FAMILY_NAME,
  CUSTOM_GIVEN_NAME, CUSTOM_PRODUCTS, EMAIL_VERIFIED_ATTRIB, PHONE_NUMBER_VERIFIED_ATTRIB, CUSTOM_ID_FLOW
} from '@eksoh/shared/common';
import { Address, Gender, Group, Locale, Product, User } from '../generated/graphql';
import { LocaleClass } from './LocaleClass';
import { UserClass } from './UserClass';

export class AuthUser implements Omit<User, 'createdAt' | 'updatedAt' | 'enabled' | 'mfaSmsActive' | 'userStatus'> {

  private idTokenPayload;
  public localeClass: LocaleClass;
  public readonly __typename: string;
  public readonly groups: Group[];
  
  constructor(
    public cognitoUser: CognitoUser
  ) {
    const userSession = cognitoUser.getSignInUserSession();
    if (!userSession) 
      throw new Error("No user session found");

    this.idTokenPayload = userSession.getIdToken().payload;
    console.debug('>>>> TOKEN', this.idTokenPayload);
    this.localeClass = new LocaleClass(this.idTokenPayload.locale as Locale);
    this.groups = this.idTokenPayload[COGNITO_GROUPS_CLAIMS] as Group[] || [];
    this.__typename = UserClass.resolveType(this.groups);
  }

  get username(): string { return this.idTokenPayload[COGNITO_USERNAME_CLAIMS]; }
  get name() { return  this.idTokenPayload.name || this.givenName + ' ' + this.familyName; }
  get givenName(): string { return this.idTokenPayload[CUSTOM_GIVEN_NAME] || this.idTokenPayload.given_name; }
  get familyName(): string { return this.idTokenPayload[CUSTOM_FAMILY_NAME] || this.idTokenPayload.family_name; }
  get email(): string { return this.idTokenPayload.email; }
  get phoneNumber(): string { return this.idTokenPayload.phone_number; }
  get products(): Product[] { return this.idTokenPayload[CUSTOM_PRODUCTS] ? JSON.parse(this.idTokenPayload[CUSTOM_PRODUCTS]) : []; }
  get address(): Address { return AuthUser.parseAddress(this.idTokenPayload.address); }
  get birthdate() { return this.idTokenPayload.birthdate ? new Date(this.idTokenPayload.birthdate) : null; }
  get middleName(): string { return this.idTokenPayload.middle_name; }
  get zoneinfo(): string { return this.idTokenPayload.zoneinfo; }
  get gender(): Gender { return this.idTokenPayload.gender }
  get emailVerified(): boolean { return String(this.idTokenPayload[EMAIL_VERIFIED_ATTRIB]) === 'true'; }
  get phoneNumberVerified(): boolean { return String(this.idTokenPayload[PHONE_NUMBER_VERIFIED_ATTRIB]) === 'true'; }
  get locale(): Locale { return this.localeClass.getLocaleEnum(); }
  get payload() { return this.idTokenPayload; }
  get idFlow(): string { return this.idTokenPayload[CUSTOM_ID_FLOW]; }


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static parseAddress(address: any) {
    const defaultAddress: Address = {
      __typename: "Address",
      formatted: null,
      streetAddress: null,
      locality: null,
      region: null,
      postalCode: null,
      country: null
    };
    try { 
      // fix TEL-382
      const addDesc = address && address.formatted ? address.formatted : address;
      const ad = addDesc ? JSON.parse(addDesc) : {};
      return {
        ...defaultAddress,
        ...ad
      } as Address;
    } catch (e) { console.log('parsing address from AWS token claim failed, returning empty address:', address, e); return defaultAddress; }
  }

  public static isExternalProviderByUsername(username: string) {
    return (username && (username.startsWith('facebook_') || username.startsWith('google_'))) || false;
  }
  
  // static fromTokenClaims(tokenClaims: { [id: string]: any }): AuthUser {
  //   function getAddr() {
  //     try { 
  //       // fix TEL-382
  //       const addDesc = tokenClaims.address && tokenClaims.address.formatted ? tokenClaims.address.formatted : tokenClaims.address
  //       return addDesc ? JSON.parse(addDesc).description : ''; 
  //     } catch (e) { console.log('parsing address from AWS token claim failed, returning empty address:', tokenClaims.address, e); return ''; }
  //   }

  //   return new this(
  //     tokenClaims.sub,
  //     tokenClaims.email,
  //     tokenClaims.given_name,
  //     tokenClaims.family_name,
  //     new LocaleClass(tokenClaims.locale),
  //     tokenClaims.gender,
  //     tokenClaims['cognito:groups'] || [],
  //     tokenClaims.updated_at,
  //     tokenClaims.phone_number,
  //     getAddr(),
  //     tokenClaims.birthdate,
  //   );
  // }
}