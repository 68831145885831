import { FLO_CA, Constants, NO_REPLY_EMAIL } from '@eksoh/shared/common';

export const ConstFlo = {
  commons: {
    ...Constants
  },
  email: {
    from: {
      cv: `Flowrence CV <cv@${FLO_CA}>`,
      noReply: NO_REPLY_EMAIL,
      info: `info@${FLO_CA}`
    },
    to: {
      rdv: `Rendez-Vous <rdv@${FLO_CA}>`
    }
  },
}
