import { useContext, useEffect } from 'react';
// import { Platform } from 'react-native';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { en, registerTranslation } from 'react-native-paper-dates';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
//
import {
  AppGlobals, AppAuth, authStore, AppNotif, notifStore, Geolocation, TextChat, ClientEmr,
} from '@eksoh/shared/ui';
import { AppNative } from '@eksoh/shared/ui-expo';
import useCachedResources from './hooks/useCachedResources';
import Navigation from './navigation';
import LinkingConfiguration from './navigation/LinkingConfiguration';

// ref: https://stackoverflow.com/questions/44603362/setting-a-timer-for-a-long-period-of-time-i-e-multiple-minutes
import { LogBox } from 'react-native';
LogBox.ignoreLogs(['Setting a timer']);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resources: { [key: string]: any } = {
  en: require('../../assets/locales/en.json'),
  fr: require('../../assets/locales/fr.json'),
};

// init translation
i18n.use(resourcesToBackend((language, namespace, callback) => {
  const lang = language?.length >= 2 ? language.substring(0, 2) : 'en';
  callback(null, resources[lang]);
}))
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    // TODO @fp - consider using namespace for fe and m...
    // ns: ['translation'],
    // defaultNS: 'translation',
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        // if (value instanceof Date) {
        //   const d = DateTime.fromJSDate(value, { locale: lng });
        //   return format ? d.toFormat(format) : d.toLocaleString();
        // }
        return value;
      },
    },
    react: {
      useSuspense: true,
    }
  }, (err, t) => console.log(err || '(no errors)', t('about.poweredBy')));

registerTranslation('fr', {
  save: 'sauvegarder',
  selectSingle: 'choisir une date',
  selectMultiple: 'choisir les dates',
  selectRange: 'choisir une période',
  notAccordingToDateFormat: (inputFormat) => `le format de date doit être ${inputFormat}`,
  mustBeHigherThan: (date) => `doit être après ${date}`,
  mustBeLowerThan: (date) => `doit être avant  ${date}`,
  mustBeBetween: (startDate, endDate) => `doit être entre ${startDate} - ${endDate}`,
  dateIsDisabled: 'journée pas autorisée',
  previous: 'avant',
  next: 'après',
  typeInDate: 'type de date',
  pickDateFromCalendar: 'choisir une date du calendrier',
  close: 'fermer',
});
registerTranslation('en', en);

// TODO: Check - We have an issue in web. After logout, login again, click tab 2 then tab 1, it
// reloads <App />. Feels like the whole app gets refresh and redraw then AppAuth context/state
// re-init and the login provider (google) pops up. Check why...
function AppContainer() {
  const { authState } = useContext(authStore);
  const { requestPermissions } = useContext(notifStore);

  useEffect(() => {
    requestPermissions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    <Navigation isSignedIn={authState.isSignedIn} />
    <StatusBar />
  </>
}

export default function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  }
  else {
    return <SafeAreaProvider>
      <AppNative linkingOptions={LinkingConfiguration}>
        <AppGlobals appName='flowrence inc.'>
          <AppAuth>
            <AppNotif useVapid={true}> {/* Platform.OS === 'web'}> */}
              <Geolocation>
                <TextChat>
                  <ClientEmr>
                    <AppContainer />
                  </ClientEmr>
                </TextChat>
              </Geolocation>
            </AppNotif>
          </AppAuth>
        </AppGlobals>
      </AppNative>
    </SafeAreaProvider>
  }
}
